import { useEffect } from "react";

import HeroProduct from "./HeroProduct";

export default function Products() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeroProduct />
    </>
  );
}
