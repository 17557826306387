import { useEffect } from "react";
import productsData from "../../data/productsData.json";

import HomeHero from "./HomeHero";
import Bonus from "./Bonus";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const productsDataToUse = productsData.filter((data) => {
    if (data.isProduct) {
      return data;
    }
  });

  const bonusData = productsData.filter((data) => {
    if (data.isBonus) {
      return data;
    }
  });

  return (
    <>
      <HomeHero productsDataToUse={productsDataToUse} />

      <Bonus bonusData={bonusData} />
    </>
  );
}
