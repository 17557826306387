import Header from "../components/Header";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";

export default function AppLayout() {
  return (
    <>
      <Header />

      <main className="content-mt content-height flex flex-col gap-12">
        <Outlet />
      </main>

      <Footer />
    </>
  );
}
