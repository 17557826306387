import { useOutletContext } from "react-router-dom";
import { useEffect } from "react";

export default function Ebook() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const productData = useOutletContext();

  const ebookImagesElements = productData.ebookImages.map(
    (ebookImage, index) => {
      return (
        <li key={index}>
          <div className="border-b-4 border-[rgba(0,0,0,0.9)]">
            <picture>
              <img src={ebookImage} alt="" />
            </picture>
          </div>
        </li>
      );
    }
  );

  return (
    <div className="flex flex-col max-w-2xl m-auto">
      <ol>{ebookImagesElements}</ol>
    </div>
  );
}
