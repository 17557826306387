import { Link, Outlet, useParams, useLocation } from "react-router-dom";
import productsData from "../data/productsData.json";

export default function ProductsLayout() {
  const { productName } = useParams();
  const productUrl = productName;

  const location = useLocation();

  let isAllowed = false;
  let currentProduct = {};
  let previousPage = "..";

  for (
    let productIndex = 0;
    productIndex < productsData.length;
    productIndex++
  ) {
    const productData = productsData[productIndex];

    if (productData.urlName === productUrl) {
      isAllowed = true;
      currentProduct = productData;

      const currentPage = `/${currentProduct.urlName}`;

      if (currentPage !== location.pathname) {
        previousPage = `/${currentProduct.urlName}`;
      } else {
        previousPage = "..";
      }

      break;
    }
  }

  return isAllowed ? (
    <section className="py-11 text-customBlack-black lg:px-4">
      <div className="flex flex-col gap-10 w-full max-w-7xl m-auto">
        <article className="px-4 text-lg lg:px-0">
          <Link to={previousPage} className="flex gap-2 items-center w-fit">
            <i className="icon-long-arrow-left text-3xl"></i>

            <mark className="bg-transparent font-bold text-customBlack-black">
              {" "}
              Voltar{" "}
            </mark>
          </Link>
        </article>

        <Outlet context={currentProduct} />
      </div>
    </section>
  ) : (
    <h1>Not found</h1>
  );
}
