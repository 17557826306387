import logo from "../assets/logos/logo.webp";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header className="bg-secondary fixed z-40 top-0 left-0 right-0 header-height header-shadow">
      <div className="flex items-center justify-center p-4 py-8 gap-5">
        <Link to="/">
          <picture>
            <img src={logo} className="h-12" />
          </picture>
        </Link>
      </div>
    </header>
  );
}
