import { Link } from "react-router-dom";

export default function HomeHero(props) {
  const productsElements = props.productsDataToUse.map((productData) => {
    return (
      <ProductContainer
        key={productData.id}
        title={productData.title}
        content={productData.content}
        imgUrl={productData.imgUrl}
        sendTo={`/${productData.urlName}`}
      />
    );
  });

  return (
    <section className="px-4 pt-6">
      <article className="flex flex-col items-center justify-center gap-7 max-w-7xl m-auto">
        <h1 className="font-bold text-primary main-text-header text-center">
          O Truque Da Cerveja
        </h1>

        <div className="flex flex-wrap justify-center gap-5">
          {productsElements}
        </div>
      </article>
    </section>
  );
}

function ProductContainer(props) {
  return (
    <article className="flex flex-col rounded-md w-full shadow-md md:max-w-[297px] 2xl:max-w-[305px]">
      <picture>
        <img src={props.imgUrl} alt="" className="w-full rounded-t-md" />
      </picture>

      <article className="flex flex-col gap-4 pt-4 pb-4 justify-between px-4 h-full border-l border-r border-b border-[rgba(0,0,0,0.3)]">
        <article className="flex flex-col gap-4">
          <h2 className="font-bold text-primary text-lg">{props.title}</h2>

          <p className="font-normal text-customBlack-black text-base">
            {props.content}
          </p>
        </article>

        <Link
          to={props.sendTo}
          className="button bg-primary text-white text-base"
        >
          Saber Mais
        </Link>
      </article>
    </article>
  );
}
